<template>
  <div>
    <base-loading v-if="!ready" />
    <v-container
      v-else
      fluid
    />
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  import DashboardStore, { BOOTSTRAP } from '@/store/modules/dashboard'

  export default {
    name: 'DashboardFornecedor',
    data () {
      return {
        styles: {
          width: '500px',
          height: '300px',
          background: '#2a2744',
        },
      }
    },
    computed: {
      ...mapState({ user: state => state.user }),
      ...mapState('dashboard', ['ready', 'dashboard']),
      ...mapGetters(['hasPermission']),
    },
    created () {
      if (!this.$store.hasModule('dashboard')) { this.$store.registerModule('dashboard', DashboardStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('dashboard', [BOOTSTRAP]),
    },
  }
</script>

<style lang="scss">

.graficoLine .ct-square {
  height: 290px;
}

.graficoPieTec .ct-square {
  height: 290px;
}

.graficoPieCat .ct-square {
  height: 290px;
}

.graficoLine .v-card--material-chart .v-card--material__heading {
  max-height: 315px;
}

.graficoPieTec .v-card--material-chart .v-card--material__heading {
  max-height: 315px;
}

.graficoPieCat .v-card--material-chart .v-card--material__heading {
  max-height: 315px;
}

.graficoLine .v-card--material-chart .theme--dark.v-card {
  background-color: white !important
}

.graficoPieTec .v-card--material-chart .theme--dark.v-card {
  background-color: white !important
}

.graficoPieCat .v-card--material-chart .theme--dark.v-card {
  background-color: white !important
}

.graficoPieTec .ct-legend {
  position: relative;
  z-index: 10;
  list-style: none;
  text-align: left;

  li {
    position: relative;
    padding-left: 23px;
    margin-right: 10px;
    margin-bottom: 3px;
    cursor: pointer;
    display: block;
  }
}

.graficoPieCat .ct-legend {
  position: relative;
  z-index: 10;
  list-style: none;
  text-align: left;

  li {
    position: relative;
    padding-left: 23px;
    margin-right: 10px;
    margin-bottom: 3px;
    cursor: pointer;
    display: block;
  }
}
</style>
